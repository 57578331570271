<template>
  <div class="smartDTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          人力资源决策分析系统
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="mainFunction">
        <IntroduceCare
          :fontDesc="mainFunction"
          class="mainFunctionCard"
        ></IntroduceCare>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">系统功能图</h4>
        <span class="line"></span>
        <img src="@/assets/product/peerEvaluation/gnt.png" alt="" style="width:900px;margin-top:40px">
      </div>

      <div class="systemValue">
        <h5 class="descH4">系统价值优势</h5>
        <span class="line"></span>
        <div class="systemValueContent">
          <div v-for="(item,index) in valueList" :key="index" class="valueItem">
            <div class="valueItemMask"></div>
            <div class="valueTitle">{{item.title}}</div>
            <div class="valueLine"></div>
            <div class="valueText">{{item.conten}}</div>
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductPeer',
  data () {
    return {
      valueList: [
        { title: '人力数量分析', conten: '了解人员配比，为人才引进、人员配备数量提供决策支持。' },
        { title: '人力结构分析', conten: '人力结构分析，为人才队伍建设，优化人员结构提供决策支持。' },
        { title: '人力质量分析', conten: '人力质量分析，对人才价值评估，了解职工绩效能力提供决策支持。' }
      ],
      mainFunction: [
        {
          title: '人力资源决策分析系统',
          fontLeft: false,
          p: [
            {
              desc: '基于职工数据，通过数据统计、数据分析，实现人才数量、结构、质量的分析，为人才的储备、培养、任用提供决策支持。'
            }
          ],
          src: require('@/assets/product/peerEvaluation/saft.png')
        }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moPeer')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.smartDTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/peerEvaluation/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.mainFunction{
width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 40px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemArchitecture{
    width: 100%;
height: 862px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.systemValue{
      width: 100%;
height: 480px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.systemValueContent{
  width: 1200px;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  margin-right: 24px;
  height: 240px;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  .valueTitle{
    font-weight: 500;
    font-size: 24px;
    color: #222222;
    padding-top: 9px;
  }
  .valueLine{
    width: 30px;
    height: 4px;
    margin: 14px 0;
    background: #D70C0C;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #444;
    text-align: center;
  }
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:last-child{
  margin-right: 0px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
    background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueText{
    color: #fff;
  }
}
</style>
